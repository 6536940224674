<template>
  <b-overlay :show="filmBusy">
    <portlet title="قىسىم ئۇچۇرى تەھرىرلەش">
      <template v-slot:body>
        <episode-form
          action="edit"
          ref="episode"
          :model-id="modelId"
          @loading="onLoading"
          @loaded="onLoaded"
          @updating="onLoading"
          @updated="onUpdated"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          ساقلاش
        </b-button>
        <b-button @click="onClickReset" class="ml-2" variant="danger">
          بىكار قىلىش
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import EpisodeForm from "@v@/components/forms/EpisodeForm";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { debugConsole } from "@c@/helpers/helpers";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import filmMixin from "@m@/film.mixin";
  import episodeMixin from "@m@/film.episode.mixin";
  export default {
    name: "index",
    components: { Portlet, EpisodeForm },
    mixins: [ formBusyMixin, sweetAlertMixin, filmMixin, episodeMixin ],
    computed: {
      modelId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.id;
      },
      film_id(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.film_id;
      },
      film_title(){
        return this.film.title || 'نامەلۇم';
      },
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'فىلىم باشقۇرۇش', route: {name: 'film.home'}});
      BreadCrumbs.addBreadCrumb({title: `${this.film_title}`, route: { name: 'episode.home', params: {film_id: this.film_id}}});
      BreadCrumbs.addBreadCrumb({title: 'قىسىم ئۇچۇرى تەھرىرلەش'});
      if ( this.$refs.hasOwnProperty('episode') ){
        this.form = this.$refs['episode'];
      }
    },
    data(){
      return {
        filmBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onLoading(){
        this.showBusy('filmBusy');
        debugConsole({prefix: 'episode', message: 'onLoading'})
      },
      onLoaded({response, state}){
        this.hideBusy('filmBusy');
        debugConsole({prefix: 'episode', message: `onLoaded: ${state}`});
      },
      onUpdated({response, state}){
        this.hideBusy('filmBusy');
        debugConsole({prefix: 'episode', message: `onUpdated: ${state}`});
        if ( state === 'success' ){
          this.success({ message: 'قىسىم ئۇچۇرى تەھرىرلەش مۇۋاپىقىيەتلىك بولدى.' });
        }
      },
      onClickReset(){
        this.$router.replace({name: 'episode.home', params: {film_id: this.film_id}});
      },
    }
  }
</script>

<style scoped>

</style>
