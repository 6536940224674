<template>
  <div class="time-fragment" v-if="start_time && end_time">
    <b-button-group>
      <b-button @click="onClickDelete" variant="outline-primary">
        <i class="fa fa-times p-0"></i>
      </b-button>
      <b-button @click="onClickEdit" variant="outline-primary">
        <span class="start_time">{{ start_time }}</span>
        <i class="fa fa-angle-double-left"></i>
        <span class="start_time">{{ end_time }}</span>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
  export default {
    name: "TimeFragmentItem",
    props: {
      start_time: String,
      end_time: String,
      index: {
        type: [String, Number]
      }
    },
    methods: {
      onClickDelete(){
        let { index } = this;
        this.$emit('delete', index);
      },
      onClickEdit(){
        let { index } = this;
        this.$emit('edit', index);
      },
    }
  }
</script>

<style scoped>
  .btn i {
    padding-right: 0.5rem;
  }
</style>
