<template>
  <div class="model-container">
    <base-modal :id="id" :title="title" :modal-busy="busy" :size="size" v-model="modalState" >
      <b-row>
        <b-col>
          <b-input-group>
            <b-form-timepicker hide-header show-seconds :state="start_state" aria-describedby="start-live-feedback" :hour12="false" no-close-button menu-class="direction-ltr" locale="de" style="direction: ltr" v-model="start" placeholder="باشلىنىش ۋاقتىنى تاللاڭ"></b-form-timepicker>
            <b-input-group-prepend is-text><i class="fa fa-long-arrow-alt-left"></i></b-input-group-prepend>
            <b-form-timepicker hide-header show-seconds :state="end_state" aria-describedby="end-live-feedback" :hour12="false" no-close-button menu-class="direction-ltr" locale="de" style="direction: ltr" v-model="end" placeholder="ئ‍اياقلىشىش ۋاقتىنى تاللاڭ"></b-form-timepicker>
            <b-input-group-append>
              <b-button size="sm" text="Button" variant="success" @click="onClickSend">ساقلاش</b-button>
            </b-input-group-append>
            <b-form-invalid-feedback id="start-live-feedback">{{ start_feedback }}</b-form-invalid-feedback>
            <b-form-invalid-feedback id="end-live-feedback">{{ end_feedback }}</b-form-invalid-feedback>
          </b-input-group>
        </b-col>
      </b-row>
    </base-modal>
  </div>
</template>

<script>
  import baseModal from "@v@/components/modals/base.modal";
  export default {
    name: "timeFragmentModal",
    components: { baseModal },
    props: {
      id: {
        type: String,
        default: () => 'time-fragment-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'ۋاقىت بۆلىكى قوشۇڭ'
      },
      busy: Boolean,
      size: {
        type: String,
        default: ()=> 'lg'
      },
      start_time: String,
      end_time: String,
      index: {
        type: [ String, Number ]
      }
    },
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
    },
    watch: {
      start_time: {
        handler(val, oldVal){
          if ( val )
            this.start = val;
        }
      },
      end_time: {
        handler(val, oldVal){
          if ( val )
            this.end = val;
        }
      },
      index: {
        handler(val, oldVal){
          this.current_index = val;
        }
      }
    },
    data(){
      return {
        start: '00:00:00',
        end: '00:00:00',
        current_index: null,
        start_state: null,
        end_state: null,
        start_feedback: 'باشلىنىش ۋاقتى ئاياقلىشىش ۋاقتىدىن كىچىك بولسۇن',
        end_feedback: '',
      };
    },
    methods: {
      onClickSend(){
        let { start: start_time, end: end_time, current_index: index } = this;
        let startRe = start_time.replaceAll(':', '');
        let endRe = end_time.replaceAll(':', '');
        if ( ! start_time || ! end_time ){
          if ( ! start_time && ! end_time ){
            this.start_feedback = 'باشلىنىش ۋە ئاياقلىشىش ۋاقتىنى تاللاڭ ';
            this.end_state = false;
            this.start_state = false;
          }else if ( ! start_time ){
            this.start_feedback = 'باشلىنىش ۋاقتىنى تاللاڭ';
            this.start_state = false;
            this.end_state = true;
            this.end_feedback = '';
          }else if ( !end_time) {
            this.end_feedback = 'ئاياقلىشىش ۋاقتىنى تاللاڭ';
            this.end_state = false;
            this.start_state = true;
            this.start_feedback = '';
          }
          return;
        }
        if ( endRe == 0 ){
          this.end_feedback = 'ئاياقلىشىش ۋاقتىنى تاللاڭ';
          this.end_state = false;
          this.start_state = true;
          this.start_feedback = '';
          return;
        }else {
          this.start_feedback = '';
          this.end_feedback = '';
        }
        if ( startRe >= endRe ){
          this.start_feedback = 'باشلىنىش ۋاقتى ئاياقلىشىش ۋاقتىدىن كىچىك بولسۇن';
          this.start_state = false;
          return;
        }
        this.start_state = true;
        this.end_state = true;
        this.$emit('send', {start_time, end_time, index});
        this.$nextTick(function () {
          this.start = '00:00:00';
          this.end = '00:00:00';
          this.current_index = null;
          this.start_state = null;
          this.end_state = null;
        });
      }
    }
  }
</script>

<style scoped>

</style>
