<template>
  <div class="film-home position-relative">
    <b-overlay :show="filmBusy">
      <pagination :meta="filmEpisodeMeta" :implement-search="false" @change-page="onChangePage" @refresh="onRefresh">
        <b-button slot="action" :to="{name: 'episode.store', param: {film_id: film.id}}" class="btn btn-label-success btn-sm border-0">
          يېڭى قىسىم قوشۇش
        </b-button>
      </pagination>
      <b-row>
        <b-col v-for="(episode, index) in filmEpisodes" :key="`film_episode_${episode.id}`" md="3" sm="6">
          <portlet :title="episode.title ? episode.title : `${filmEpisodeMeta.total - ((filmEpisodeMeta.current_page - 1) * filmEpisodeMeta.per_page) - index} - قىسىم `" body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-text >
                  <b-checkbox switch v-model="episode.is_show" @change="onChangeSwitch( episode.id, 'is_show', $event )"> ھالىتى </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-item :to="{name: 'episode.edit', params: {id: episode.id}}">
                  <i class="fa fa-info-circle"></i> تەھرىرلەش
                </b-dropdown-item>
                <b-dropdown-item @click="onClickDelete(episode.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">ھەقلىقمۇ؟ </span>
                        <span class="kt-widget__data">{{ episode.need_payment ? `${episode.price} يۈەن` : 'ھەقسىز' }}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">VIP تەلەپ قىلامدۇ؟ </span>
                        <span class="kt-widget__data">{{ episode.need_vip ? 'شۇنداق' : 'ياق' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!filmEpisodes.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن قىسىم قوشۇڭ." >
        <template slot="action">
          <b-button :to="{name: 'episode.store', param: {film_id: film.id}}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-warning">قىسىم قوشۇش</b-button>
        </template>
      </no-result>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import filmMixin from "@m@/film.mixin";
  import filmEpisodeMixin from "@m@/film.episode.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import pagination from "@v@/components/paginate";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import {SET_REGION_ERROR} from "@/store/modules/region.module";
  import LC from "@c@/storage/localStorage";
  import {SET_FILM} from "@/store/modules/film.module";
  import {SET_FILM_EPISODE_ERROR} from "@/store/modules/film.episode.module";

  export default {
    name: "index",
    components: { Portlet, pagination, NoResult },
    mixins: [filmMixin, filmEpisodeMixin, formBusyMixin, commonMethodMixin, msgBoxConfirmMixin],
    data(){
      return {
        filmBusy: false,
        page: 1,
        title: ''
      };
    },
    computed: {
      film_id(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.film_id;
      }
    },
    created() {
      if ( !this.film ){
        if ( LC.has(`film_${this.film_id}`) ){
          this.$store.commit(SET_FILM, LC.get(`film_${this.film_id}`))
        }else {
          this.getFilm(this.film_id);
        }
      }else {
        if ( this.film.id !== this.film_id){
          this.getFilm(this.film_id);
        }
      }
      // this.getFilmEpisodes(this.film_id);
      this.autoRefresh();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'فىلىم باشقۇرۇش', route: {name: 'film.home'}});
      BreadCrumbs.addBreadCrumb({title: `${this.film.title}`});
      BreadCrumbs.addBreadCrumb({title: 'بارلىق قىسىملىرى'});
    },
    methods: {
      autoRefresh(rootPage = null, rootTitle = null){
        let { filmEpisodePage, title, film_id } = this;
        let fid = filmEpisodePage.hasOwnProperty('film_id') && filmEpisodePage.film_id ? filmEpisodePage.film_id : film_id;
        let e_page = filmEpisodePage.hasOwnProperty('page') && filmEpisodePage.page ? filmEpisodePage.page : 1;
        this.getFilmEpisodes(fid, rootPage ? rootPage : e_page, rootTitle ? rootTitle : title);
      },
      onChangePage(e){
        let { title, page } = e;
        this.page = page; this.title = title;
        this.autoRefresh(page, title);
        // this.getFilmEpisodes(this.film_id, page, title);
      },
      onRefresh(title){
        // this.getFilmEpisodes(this.film_id);
        this.autoRefresh(1, title);
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: `films/episodes`,
          id, column, mutationType: SET_FILM_EPISODE_ERROR,
          begin: ()=>{ this.showBusy('filmBusy') },
          after: ()=>{ this.hideBusy('filmBusy') },
        });
      },
      onClickDelete(id){
        this.msgBoxConfirm({
          message: 'مەزكۇر قىسىمنى ئۆچۈرسىڭىز ئەسلىگە قايتۇرالمايسىز، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟',
          confirmCallback: confirm=>{
            if ( confirm ){
              this.deleteModel({
                id, mutationType: SET_REGION_ERROR, resource: 'episodes',
                begin: ()=>{ this.showBusy('filmBusy')},
                after: ()=>{
                  // let { title, page } = this;
                  // this.getFilmEpisodes(this.film_id, page, title);
                  this.autoRefresh();
                  this.hideBusy('filmBusy');
                },
              })
            }
          }
        });
      },
    }
  }
</script>

<style scoped>

</style>
