<template>
  <b-overlay :show="filmBusy">
    <portlet title="يېڭى قىسىم قوشۇش">
      <template v-slot:body>
        <episode-form
          ref="episode"
          action="create"
          :parent-model-id="film_id"
          @storing="onStoring"
          @stored="onStored"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          ساقلاش
        </b-button>
        <b-button class="ml-2" @click="onClickReset" variant="danger">
          بىكار قىلىش
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import EpisodeForm from "@v@/components/forms/EpisodeForm";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import filmMixin from "@m@/film.mixin";
  import episodeMixin from "@m@/film.episode.mixin";
  import { debugConsole } from "@c@/helpers/helpers";
  import LC from "@c@/storage/localStorage";
  import {SET_FILM} from "@/store/modules/film.module";
  export default {
    name: "index",
    components: { Portlet, EpisodeForm },
    mixins: [ formBusyMixin, filmMixin, episodeMixin ],
    computed: {
      film_id(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.film_id;
      },
      film_title(){
        return this.film.title || 'نامەلۇم';
      },
    },
    created() {
      if ( !this.film ){
        if ( LC.has(`film_${this.film_id}`) ){
          this.$dispatch(SET_FILM, LC.get(`film_${this.film_id}`))
        }else {
          this.getFilm(this.film_id);
        }
      }else {
        if ( this.film.id !== this.film_id){
          this.getFilm(this.film_id);
        }
      }
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'فىلىم باشقۇرۇش', route: {name: 'film.home'}});
      BreadCrumbs.addBreadCrumb({title: `${this.film_title}`, route: { name: 'episode.home', params: {film_id: this.film_id}}});
      BreadCrumbs.addBreadCrumb({title: 'يېڭى قىسىم قوشۇش'});
      if ( this.$refs.hasOwnProperty('episode') ){
        this.form = this.$refs['episode'];
      }
    },
    data(){
      return {
        filmBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onStoring(){
        this.showBusy('filmBusy');
        debugConsole({prefix: 'episode', message: `onStored`});
      },
      onStored(state){
        this.hideBusy('filmBusy');
        debugConsole({prefix: 'episode', message: `onStored: ${state}`});
        this.$router.replace({name: 'episode.home'});
      },
      onClickReset(){
        this.$router.replace({name: 'episode.home', params: {film_id: this.film_id}});
      },
    }
  }
</script>

<style scoped>

</style>
